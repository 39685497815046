import Plus from '../../../svgs/plus.svg';
import Minus from '../../../svgs/minus.svg';
import Star from '../../../svgs/star.svg';
import Star2 from '../../../svgs/star2.svg';
import CheckIn from '../../../svgs/check-in.svg';
import CheckOut from '../../../svgs/check-out.svg';
import Person from '../../../svgs/person.svg';
import Subtract from '../../../svgs/subtract.svg';
import ChevronDown from '../../../svgs/chervon-down.svg';
import ArrowRight from '../../../svgs/arrow-right.svg';
import ChevronLeft from '../../../svgs/chevron-left.svg';
import BookmarkOutlined from '../../../svgs/bookmark-outlined.svg';
import ShareAlt from '../../../svgs/share-alt.svg';
import FloorAlt from '../../../svgs/floor-alt.svg';
import Night from '../../../svgs/night.svg';
import BathOutlined from '../../../svgs/bath-outlined.svg';
import BathOutlined2 from '../../../svgs/bath-outlined2.svg';
import BedOutlined from '../../../svgs/bed-outlined.svg';
import BedOutlined2 from '../../../svgs/bed-outlined2.svg';
import MoreInfo from '../../../svgs/more-info.svg';
import Dates from '../../../svgs/dates.svg';
import Dates2 from '../../../svgs/dates2.svg';
import ChevronRight from '../../../svgs/chevron-right.svg';
import CancelMiddle from '../../../svgs/cancel-middle.svg';
import CopyOutlined from '../../../svgs/copy-outlined.svg';
import FacebookIcon from '../../../svgs/facebook-icon.svg';
import TrashBin from '../../../svgs/trash-bin.svg';
import ShowerOutlined from '../../../svgs/shower-outlined.svg';
import Photos from '../../../svgs/photos.svg';
import PhotosOutlined from '../../../svgs/photos-outlined.svg';
import Home from '../../../svgs/home.svg';
import Home2 from '../../../svgs/home2.svg';
import LocationOutlined from '../../../svgs/location-outlined.svg';
import LocationOutlined2 from '../../../svgs/location-outlined2.svg';
import RulesOutlined from '../../../svgs/rules-outlined.svg';
import RulesOutlined2 from '../../../svgs/rules-outlined2.svg';
import Wifi from '../../../svgs/WiFi.svg';
import Wifi2 from '../../../svgs/WiFi2.svg';
import PhotoOutlined from '../../../svgs/photo-outlined.svg';
import PhotoOutlined2 from '../../../svgs/photo-outlined2.svg';
import DescriptionOutlined from '../../../svgs/description-outlined.svg';
import DescriptionOutlined2 from '../../../svgs/description-outlined2.svg';
import PriceTagOutlined from '../../../svgs/price-tag-outlined.svg';
import PriceTagOutlined2 from '../../../svgs/price-tag-outlined2.svg';
import RegulationsOutlined from '../../../svgs/regulations-outlined.svg';
import RegulationsOutlined2 from '../../../svgs/regulations-outlined2.svg';
import Add from '../../../svgs/add.svg';
import BigCheckmark from '../../../svgs/big-checkmark.svg';
import CurrentLocationOutlined from '../../../svgs/current-location-outlined.svg';
import SelectionSquare from '../../../svgs/selection-square.svg';
import ExtraFee from '../../../svgs/extra-fee.svg';
import RadioCircle from '../../../svgs/radio-circle.svg';
import RadioCircleSelected from '../../../svgs/radio-circle-selected.svg';
import PencilOutlinedAlt from '../../../svgs/pencil-outlined-alt.svg';
import PencilOutlined from '../../../svgs/pencil-outlined.svg';
import MoreInfoFilled from '../../../svgs/more-info-filled.svg';
import Logo from '../../../svgs/logo.svg';
import CheckmarkInSquare from '../../../svgs/checkmark-in-square.svg';
import FaceMaskOutlined from '../../../svgs/face-mask-outlined.svg';
import DoorOutlined from '../../../svgs/door-outlined.svg';
import More from '../../../svgs/more.svg';
import ArrowRightSmall from '../../../svgs/arrow-right-small.svg';
import BaggageOutlined from '../../../svgs/baggage-outlined.svg';
import PropertyType from '../../../svgs/property-type.svg';
import Handbag from '../../../svgs/handbag.svg';
import Wallet from '../../../svgs/wallet.svg';
import Shield from '../../../svgs/shield.svg';
import SpoonAndForkOutlined from '../../../svgs/spoon-and-fork-outlined.svg';
import BicycleOutlined from '../../../svgs/bicycle-outlined.svg';
import MessageOutlined from '../../../svgs/message-outlined.svg';
import HeartOutlined from '../../../svgs/heart-outlined.svg';
import EnvelopeFilled from '../../../svgs/envelope-filled.svg';
import AffordableStays from '../../../svgs/affordable-stays.svg';
import GlobalSearch from '../../../svgs/global-search.svg';
import PeaceOfMind from '../../../svgs/peace-of-mind.svg';
import ClockOutlined from '../../../svgs/clock-outlined.svg';
import Gallery from '../../../svgs/gallery.svg';
import CopylinkIcon from '../../../svgs/CopylinkIcon.svg';
import  EmailIcon from '../../../svgs/EmailIcon.svg';
import FacebookIcon2 from '../../../svgs/FacebookIcon2.svg';
import MessangerIcon from '../../../svgs/MessangerIcon.svg';
import WhatsappIcon from '../../../svgs/WhatsappIcon.svg';
import BookmarkMobileIcon from '../../../svgs/bookmarkMobile.svg';
import SharealtMobileIcon from '../../../svgs/Share-alt-mobile.svg';
import HolidayIcon from '../../../svgs/holiday-1.svg';
import NoWishlistFound from '../../../svgs/no-wishlists.svg';
import NoWatchlistFound from '../../../svgs/no-watchlists.svg';
import HorizontalMenu from '../../../svgs/menu-horizontal.svg';
import UpArrowBold from '../../../svgs/up-arrow-bold.svg';
import ThreeDots from '../../../svgs/three-dots.svg';
import RightArrow from '../../../svgs/right-arr1.svg';
import LeftArrow from '../../../svgs/left-arr1.svg';
import PhotoFilled from '../../../svgs/photo-filled1.svg';
import Close from '../../../svgs/close1.svg';



const Wrapper = WrappedComponent => (props) => {
    let { className, 'tooltip-title': tooltip, children, ...p } = props; // eslint-disable-line prefer-const
    className = `ic-icon ${className || ''}`;
    return (
        <span
            className={className}
            tooltip-title={tooltip}
            {...p}
        >
            <WrappedComponent />
            {children || ''}
        </span>
    );
};

export const UpArrowBoldIcon = Wrapper(UpArrowBold)
export const PlusIcon = Wrapper(Plus);
export const MinusIcon = Wrapper(Minus);
export const StarIcon = Wrapper(Star);
export const StarIcon2 = Wrapper(Star2);
export const CheckInIcon = Wrapper(CheckIn);
export const CheckOutIcon = Wrapper(CheckOut);
export const PersonIcon = Wrapper(Person);
export const SubtractIcon = Wrapper(Subtract);
export const ChevronDownIcon = Wrapper(ChevronDown);
export const ArrowRightIcon = Wrapper(ArrowRight);
export const ChevronLeftIcon = Wrapper(ChevronLeft);
export const BookmarkOutlinedIcon = Wrapper(BookmarkOutlined);
export const ShareAltIcon = Wrapper(ShareAlt);
export const NightIcon = Wrapper(Night);
export const BathOutlinedIcon = Wrapper(BathOutlined);
export const BathOutlinedIcon2 = Wrapper(BathOutlined2);
export const BedOutlinedIcon = Wrapper(BedOutlined);
export const BedOutlinedIcon2 = Wrapper(BedOutlined2);
export const MoreInfoIcon = Wrapper(MoreInfo);
export const DatesIcon = Wrapper(Dates);
export const DatesIcon2 = Wrapper(Dates2);
export const ChevronRightIcon = Wrapper(ChevronRight);
export const CancelMiddleIcon = Wrapper(CancelMiddle);
export const FloorAltIcon = Wrapper(FloorAlt);
export const CopyOutlinedIcon = Wrapper(CopyOutlined);
export const FacebookIconIcon = Wrapper(FacebookIcon);
export const TrashBinIcon = Wrapper(TrashBin);
export const ShowerOutlinedIcon = Wrapper(ShowerOutlined);
export const PhotosIcon = Wrapper(Photos);
export const PhotosOutlinedIcon = Wrapper(PhotosOutlined);
export const HomeIcon = Wrapper(Home);
export const HomeIcon2 = Wrapper(Home2);
export const LocationOutlinedIcon = Wrapper(LocationOutlined);
export const LocationOutlinedIcon2 = Wrapper(LocationOutlined2);
export const RulesOutlinedIcon = Wrapper(RulesOutlined);
export const RulesOutlinedIcon2 = Wrapper(RulesOutlined2);
export const WifiIcon = Wrapper(Wifi);
export const WifiIcon2 = Wrapper(Wifi2);
export const PhotoOutlinedIcon = Wrapper(PhotoOutlined);
export const PhotoOutlinedIcon2 = Wrapper(PhotoOutlined2);
export const DescriptionOutlinedIcon = Wrapper(DescriptionOutlined);
export const DescriptionOutlinedIcon2 = Wrapper(DescriptionOutlined2);
export const PriceTagOutlinedIcon = Wrapper(PriceTagOutlined);
export const PriceTagOutlinedIcon2 = Wrapper(PriceTagOutlined2);
export const RegulationsOutlinedIcon = Wrapper(RegulationsOutlined);
export const RegulationsOutlinedIcon2 = Wrapper(RegulationsOutlined2);
export const AddIcon = Wrapper(Add);
export const BigCheckmarkIcon = Wrapper(BigCheckmark);
export const CurrentLocationOutlinedIcon = Wrapper(CurrentLocationOutlined);
export const PencilOutlinedIcon = Wrapper(PencilOutlined);
export const SelectionSquareIcon = Wrapper(SelectionSquare);
export const ExtraFeeIcon = Wrapper(ExtraFee);
export const RadioCircleIcon = Wrapper(RadioCircle);
export const RadioCircleSelectedIcon = Wrapper(RadioCircleSelected);
export const PencilOutlinedAltIcon = Wrapper(PencilOutlinedAlt);
export const MoreInfoFilledIcon = Wrapper(MoreInfoFilled);
export const LogoIcon = Wrapper(Logo);
export const CheckmarkInSquareIcon = Wrapper(CheckmarkInSquare);
export const FaceMaskOutlinedIcon = Wrapper(FaceMaskOutlined);
export const DoorOutlinedIcon = Wrapper(DoorOutlined);
export const MoreIcon = Wrapper(More);
export const ArrowRightSmallIcon = Wrapper(ArrowRightSmall);
export const BaggageOutlinedIcon = Wrapper(BaggageOutlined);
export const PropertyTypeIcon = Wrapper(PropertyType);
export const HandbagIcon = Wrapper(Handbag);
export const WalletIcon = Wrapper(Wallet);
export const Shieldicon = Wrapper(Shield);
export const SpoonAndForkOutlinedIcon = Wrapper(SpoonAndForkOutlined);
export const BicycleOutlinedIcon = Wrapper(BicycleOutlined);
export const MessageOutlinedIcon = Wrapper(MessageOutlined);
export const HeartOutlinedIcon = Wrapper(HeartOutlined);
export const EnvelopeFilledIcon = Wrapper(EnvelopeFilled);
export const AffordableStaysIcon = Wrapper(AffordableStays);
export const GlobalSearchIcon = Wrapper(GlobalSearch);
export const PeaceOfMindIcon = Wrapper(PeaceOfMind);
export const ClockOutlinedIcon = Wrapper(ClockOutlined);
export const GalleryIcon = Wrapper(Gallery);
export const FacebookIconLDP = Wrapper(FacebookIcon2);
export const EmailIconLDP = Wrapper(EmailIcon);
export const MessangerIconLDP = Wrapper(MessangerIcon);
export const WhatsappIconLDP = Wrapper(WhatsappIcon);
export const CopylinkIconLDP = Wrapper(CopylinkIcon);
export const BookmarkMobileIconLDP = Wrapper(BookmarkMobileIcon);
export const ShareMobileIconLDP = Wrapper(SharealtMobileIcon);
export const HolidayIconDashboard = Wrapper(HolidayIcon);
export const NoWishlistIconDashboard = Wrapper(NoWishlistFound);
export const NoWatchlistIconDashboard = Wrapper(NoWatchlistFound);
export const HorizontalMenuIconDashboard = Wrapper(HorizontalMenu);
export const ThreeDotsIcon = Wrapper(ThreeDots);
export const RightArrowIcon = Wrapper(RightArrow);
export const LeftArrowIcon = Wrapper(LeftArrow);
export const PhotoFilledIcon = Wrapper(PhotoFilled);
export const CloseIcon = Wrapper(Close);
